.employeesTableItem__container {
  height: 19rem;
  width: 100%;
  display: flex;
  padding: 1rem 2rem 1rem 3rem;
  box-shadow: inset 0 -1px 0 0 var(--color-borders-primary);
  font-size: 1.6rem;
  background-color: var(--color-bg-light);
}

.employeesTableItem__container_gridView {
  flex-direction: column;
  height: 40.4rem;
  width: 28rem;
  padding: 0;
  border-radius: 0.6rem;
  margin-bottom: 1rem;
  box-shadow: none;
  margin-right: 1rem;
  border: 1px solid var(--color-bg-surface);
  overflow: hidden;
}

.employeesTableItem__container_gridView:nth-child(4n) {
  margin-right: 0;
}

.employeesTableItem__img {
  position: relative;
  width: 17rem;
  height: 17rem;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-clip: content-box;
  border-radius: 1.2rem;
  margin-right: 4.5rem;
  flex: none;
  border-radius: 1.2rem;
}

.employeesTableItem__img_gridView {
  width: 100%;
  height: 28rem;
  margin-right: 0;
  border-radius: 0;
}

.employeesTableItem__img__control__container {
  position: absolute;
  left: 23.2rem;
  width: 3.8rem;
  height: 3.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-primary-disable);
  border-radius: 0.6rem;
  cursor: pointer;
}

.employeesTableItem__img__control {
  fill: var(--color-common-white);
}

.employeesTableItem__img__editControl__container {
  top: 1rem;
}

.employeesTableItem__img__deleteControl__container {
  top: 5.8rem;
}

.employeesTableItem__items {
  width: 100%;
  display: flex;
}
.employeesTableItem__items_gridView {
  flex-direction: column;
  height: 12.4rem;
  padding-top: 2.4rem;
  border-top: none;
  border-radius: 0 0 0.6rem 0.6rem;
}

.employeesTableItem__item {
  display: flex;
  align-items: center;
  word-break: break-word;
  margin-right: 2.5rem;
  width: 17.8rem;
}

.employeesTableItem__item_gridView {
  width: 100%;
  margin: 0;
  justify-content: center;
}

.employeesTableItem__item_name {
  font-weight: 500;
  width: 18.6rem;
}

.employeesTableItem__item_name_gridView {
  margin-bottom: 0.6rem;
  width: 100%;
}

.employeesTableItem__item_type_gridView {
  order: 1;
}

.employeesTableItem__city {
  width: 10rem;
}

.employeesTableItem__city_gridView {
  width: 100%;
  margin-bottom: 1.6rem;
}

.employeesTableItem__buttons {
  margin-left: auto;
  justify-content: flex-end;
  width: 9.6rem;
  margin-right: 0;
}

.employeesTableItem__button {
  height: 3.8rem;
}

.employeesTableItem__editButton {
  margin-right: 2rem;
}

@media screen and (max-width: 1230px) {
  .employeesTableItem__container_gridView:nth-child(4n) {
    margin-right: 1rem;
  }

  .employeesTableItem__container_gridView:nth-child(3n) {
    margin-right: 0;
  }
}

@media screen and (max-width: 940px) {
  .employeesTableItem__container_gridView:nth-child(3n) {
    margin-right: 1rem;
  }

  .employeesTableItem__container_gridView:nth-child(2n) {
    margin-right: 0;
  }
}
