.viewSwitcher {
  display: flex;
}

.viewSwitcher > svg {
  cursor: pointer;
}

.viewSwitcher > svg:first-of-type {
  margin-right: 1.6rem;
}

.selectedView path:last-of-type {
  fill: var(--color-primary-main) !important;
}
