.createEditEmployee__container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--font-family-primary);
}

.createEditEmployee {
  width: 83.2rem;
  height: 47.8rem;
  padding: 3rem 4rem;
  background-color: var(--color-bg-light);
  border-radius: 6px;
  box-shadow: 0 2px 24px 0 var(--color-shadow-primary);
  border: solid 1px var(--color-borders-primary);
}

.createEditEmployee__header {
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.createEditEmployee_title {
  font-size: 1.8rem;
  font-weight: bold;
  text-transform: uppercase;
  color: var(--color-text-primary);
}

.createEditEmployee__close_button {
  align-self: flex-end;
}

.createEditEmployee__footer {
  display: flex;
  justify-content: space-between;
}

.createEditEmployee__footer_button {
  width: 14rem;
  height: 3.8rem;
}
