.popover {
  z-index: var(--z-index--popover);
}

.overlay {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: var(--z-index--popover);
}
