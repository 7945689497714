.form {
    display: flex;
    width: 31rem;
    flex-direction: column;
}

.form__label {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.form__title {
  margin-bottom: 2.4rem;
  align-self: center;
}

.form__button {
  width: 20em;
  align-self: center;
  margin-top: 2.4rem;
  text-decoration: none;
}

.form__image {
  align-self: center;
}
