.dialog_header {
  padding: 2.2rem 3.2rem 0;
  display: flex;
}

.dialog_body {
  padding: 6rem 5.4rem 4.3rem;
  display: flex;
  flex-direction: column;
  width: 44rem;
  align-items: center;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;         /* Opera/IE 8+ */
}

.dialog_body_with_close_button {
  padding-top: 0;
}

.dialog__close_button {
  margin-left: auto;
}

.dialog__button {
  margin-bottom: 1.6rem;
  min-width: 20rem;
}

.placeholder__image {
  margin-top: 0;
}

.placeholder__title {
  margin-top: 1.4rem;
  margin-bottom: 1.5rem;
}

.placeholder__message {
  white-space: pre-wrap;
  margin-bottom: 5rem;
}

.placeholder_message_leftalign {
  text-align: left;
}

.errorview {
  width: 100vw;
  height: 80vh;
  padding: 20vh 0 0;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
