.form__container {
  height: 27rem;
  margin: 4rem 0;
  display: flex;
}

.form__fields {
  width: 42rem;
  display: flex;
  flex-direction: column;
}

.form__loadPhoto {
  width: 27rem;
  height: 100%;
  margin-right: 6.2rem;
  position: relative;
  cursor: pointer;
}

.form__loadPhoto__img_container {
  width: 100%;
  height: 100%;
  position: relative;
}

.form__loadPhoto__img_button_container {
  position: absolute;
  top: 1rem;
  left: 22.2rem;
  width: 3.8rem;
  height: 3.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-primary-disable);
  border-radius: 0.6rem;
}

.form__loadPhoto__img_button {
  fill: var(--color-common-white);
}

.form__loadPhoto_img {
  width: 100%;
  height: 100%;
  border-radius: 0.6rem;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.form__loadPhoto_placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px dashed var(--color-primary-main);
  border-radius: 6px;
}

.form__loadPhoto_placeholder_icon {
  margin-bottom: 1.6rem;
}

.form__loadPhoto_placeholder_progressBar {
  height: 0.4rem;
  width: 16rem;
  margin-bottom: 2rem;
}

.form__loadPhoto_placeholder_progressCount {
  font-size: 1.4rem;
  font-weight: 500;
  color: var(--color-primary-main);
}

.form__loadPhoto_placeholder_title {
  font-size: 1.4rem;
  font-weight: 500;
  color: var(--color-primary-main);
}

.form__loadPhoto_placeholder_size {
  position: absolute;
  color: var(--color-text-secondary-main);
  font-size: 1.4rem;
  top: 22.6rem;
}

.form__loadPhoto_control {
  display: none;
}

.form__field {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.form__field:last-of-type {
  margin-bottom: 0;
}

.form__field_label {
  margin-bottom: 0;
}

.form__field_inputContainer {
  width: 30rem;
  height: 3.8rem;
}

.form__field_input {
  font-size: 1.4rem;
  font-weight: 500;
  color: var(--color-text-primary);
}

.form__field_dropDownButton {
  color: var(--color-text-primary);
  width: 30rem;
  height: 3.8rem;
  display: flex;
  justify-content: space-between;
  border: solid 1px var(--color-borders-primary);
  font-weight: 500;
}

.form__field_dropDownButton span {
  font-size: 1.4rem;
}

.form__field_dropDownButton:hover {
  border: solid 1px var(--color-borders-primary) !important;
  color: var(--color-text-primary) !important;
}

.form__field_dropDownButton:focus {
  border: solid 2px var(--color-primary-main) !important;
  color: var(--color-text-primary) !important;
}

.form__field_dropDownMenu {
  width: 30rem;
}

.form__field_dropDownPopoverSpacer {
  height: 0 !important;
}

.form__field_error,
.form__field_error:focus {
  border: 2px solid var(--color-error) !important;
}
.form__field_error:focus-within {
  padding: 0 1rem;
}