.fade__opacity_enter {
  opacity: 0.01;
}

.fade__opacity_enterActive {
  opacity: 1;
  transition-property: opacity;
  transition-timing-function: ease-in;
}

.fade__opacity_exit {
  opacity: 1;
}
.fade__opacity_exitActive {
  opacity: 0.01;
  transition-property: opacity;
  transition-timing-function: ease-out;
}

.fade__left_enter {
  transform: translateX(100vw);
}

.fade__left_enterActive {
  transform: translateX(0);
  transition-property: transform;
  transition-timing-function: ease-in;
}
.fade__left_exit {
  transform: translateX(0);
}
.fade__left_exitActive {
  transform: translateX(100vw);
  transition-property: transform;
  transition-timing-function: ease-out;
}

.fade__bottom_enter {
  transform: translateY(100vh);
}
.fade__bottom_enterActive {
  transform: translateY(0);
  transition-property: transform;
  transition-timing-function: ease-in;
}
.fade__bottom_exit {
  transform: translateY(0);
}
.fade__bottom_exitActive {
  transform: translateY(100vw);
  transition-property: transform;
  transition-timing-function: ease-out;
}

.fade__top_enter {
  opacity: 0.01;
  transform: translateY(-4rem);
}

.fade__top_enterActive {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.3s, transform 0.3s;
  transition-delay: 200ms;
}

.fade__top_exit {
  opacity: 1;
  transform: translateY(0);
}

.fade__top_exitActive {
  opacity: 0.01;
  transform: translateY(-4rem);
  transition: opacity 0.3s, transform 0.3s;
}
.fade_delay_100 {
  transition-delay: 100ms;
}

.fade_delay_200 {
  transition-delay: 200ms;
}

.fade_delay_300 {
  transition-delay: 300ms;
}

.fade_delay_400 {
  transition-delay: 300ms;
}

.fade_delay_500 {
  transition-delay: 300ms;
}

.fade_delay_600 {
  transition-delay: 600ms;
}

.fade_duration_100 {
  transition-duration: 100ms;
}

.fade_duration_200 {
  transition-duration: 200ms;
}

.fade_duration_300 {
  transition-duration: 300ms;
}

.fade_duration_400 {
  transition-duration: 400ms;
}
