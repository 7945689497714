.citiesFilter__button {
  width: 18rem;
  height: 3.8rem;
  margin-right: 3rem;
  display: flex !important;
  justify-content: space-between !important;
}
.citiesFilter__menu {
  width: 18rem;
}
