.modal {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dialog__container {
  width: 44.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dialog_body {
  position: relative;
  padding: 6rem 7.1rem;
  display: flex;
  flex-direction: column;
  width: 44.2rem;
  align-items: center;
}

.dialog__close_button {
  position: absolute;
  top: 1.6rem;
  right: 2.6rem;
}

.dialog__button {
  margin-bottom: 1.6rem;
  min-width: 20rem;
}

.placeholder__image {
  margin-top: 0;
  margin-bottom: 3.2rem;
}

.placeholder__title {
  margin-bottom: 1.5rem;
}

.placeholder__message {
  white-space: pre-wrap;
  margin-bottom: 5rem;
  font-weight: normal;
}

.placeholder__button {
  margin: 0;
}
