.popover__spacer {
  height: 1rem;
}

.placeholder {
  font-weight: 500;
  color: var(--color-text-secondary-main);
}

.button__title {
  font-weight: 500;
}

.icon {
  fill: var(--color-text-secondary-main);
}
