body,
h1,
h2,
h3,
p {
  margin: 0;
}

html {
  font-size: 10px;
}

html,
body,
#root {
  height: 100%;
}

#popover_container {
  position: fixed;
  z-index: var(--z-index--popover);
}
:root {
  --z-index--popover: 30;
  --z-index--modal: 25;
}

* {
  box-sizing: border-box;
}
