.employeesTable__container {
  position: relative;
  max-height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.employeesTable {
  height: fit-content;
  width: 90rem;
  display: flex;
  flex-direction: column;
  margin: 2.4rem 0;
}

.employeesTable_gridView {
  width: 115rem;
  flex-flow: row wrap;
}

@media screen and (max-width: 1230px) {
  .employeesTable_gridView {
    width: 86rem;
  }
}

@media screen and (max-width: 940px) {
  .employeesTable_gridView {
    width: 57rem;
  }
}

@media screen and (max-width: 650px) {
  .employeesTable_gridView {
    width: 29rem;
  }
}
