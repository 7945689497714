.header__container {
  z-index: var(--z-index-header);
  position: fixed;
  width: 100%;
  height: var(--header-height);
  display: flex;
  justify-content: center;
  background-color: var(--color-bg-light);
  box-shadow: 0 8px 20px 0 var(--box-shadow-primary);
}

.header {
  width: 144rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 4rem 0 1rem;
}

.header__logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 8.2rem;
  height: 8rem;
  margin-right: 0.8rem;
}

.header__title {
  font-size: 1.4rem;
  font-weight: bold;
  margin-right: 4.2rem;
  cursor: default;
  color: var(--color-text-primary);
}
