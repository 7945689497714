.item {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  padding: 0.9rem 1rem 0.9rem 1.6rem;
}

.item:hover {
  background-color: var(--color-table-hover);
  cursor: pointer;
}

.item_selected {
  background-color: var(--color-table-hover);
}

.item__label {
  max-width: 80%;
  font-weight: 500;
  word-break: break-word;
}

.item__icon {
  flex-shrink: 0;
  fill: var(--color-primary-main);
}
