.menu {
  width: 20rem;
  padding: 1rem 0;
  --padding-item: 3rem;
}

.menuWithScroll {
  height: calc(100vh - 145px);
  overflow: hidden;
}

.scrollBarContainer {
  overflow: hidden;
  /* overflow: scroll !important; */
  scrollbar-width: none; /* Hide native scrollbar for Firefox */
}

.scrollBarContainer :global .ps__thumb-y {
  opacity: 1;
  margin-right: 0px;
}

.scrollBarContainer::-webkit-scrollbar {
  display: none; /* Hide native scrollbar for Chrome, Safari and Opera */
}
