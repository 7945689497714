.root {
  background-color: var(--color-bg-main);
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  --header-height: 8rem;
  font-family: var(--font-family-primary);
  color: var(--color-text-primary);
}

.contentContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: var(--header-height);
  overflow: hidden;
}

.noData_placeholder_container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}


.paper {
  position: absolute;
  padding: 4rem 6.6rem 5rem;
  display: flex;
  flex-direction: column;
  min-width: 31rem;
  min-height: 35rem;
}

.substrate {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
}