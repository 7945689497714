.filters {
  display: flex;
  width: 87rem;
}

.filters__buttons {
  display: flex;
  align-items: center;
}

.filters__search_inputContainer {
  width: 30rem;
  height: 3.8rem;
  margin-right: 3rem;
}
