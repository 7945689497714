.button {
  display: flex;
  align-items: center;
  border-radius: 0.6em;
  cursor: pointer;
  padding: 0.6rem 1rem;
  text-align: center;
  font-size: 1em;
  border: none;
  justify-content: center;
}

.button span {
  padding: 0.3rem 0.4rem;
}

.button {
  padding: 0.6rem;
}

.button_size_small {
  padding: 0.2rem;
}

.button_size_smallWide {
  padding: 0.2rem 0.5rem;
}

.button_size_large {
  min-width: 140px;
}

.button_enlargedLeftPadding {
  padding-left: 0.9rem;
}

.button_enlargedRightPadding {
  padding-right: 0.9rem;
}

.button:focus {
  outline: none;
}

.button_color_primary {
  --color-first: var(--color-primary-main);
  --color-first--hover: var(--color-primary-light);
  --color-first--active: var(--color-primary-dark);

  --color-second: var(--color-common-white);
  --color-second--hover: var(--color-common-white);
  --color-second--active: var(--color-common-white);
}

.button_color_secondary {
  --color-first: var(--color-secondary-main);
  --color-first--hover: var(--color-secondary-light);
  --color-first--active: var(--color-secondary-dark);

  --color-first-text: var(--color-text-secondary-main);
  --color-first-text--hover: var(--color-text-secondary-light);
  --color-first-text--active: var(--color-text-secondary-dark);

  --color-second: var(--color-common-white);
  --color-second--hover: var(--color-common-white);
  --color-second--active: var(--color-common-white);
}

.button_type_filled {
  border: 1px solid var(--color-first);
  background-color: var(--color-first);
  border-color: var(--color-first);
  color: var(--color-second);
}

.button_type_filled:hover:enabled {
  background-color: var(--color-first--hover);
  border-color: var(--color-first--hover);
  color: var(--color-second--hover);
}

.button_type_filled:active:enabled,
.button_type_filled:focus:enabled {
  background-color: var(--color-first--active);
  border-color: var(--color-first--active);
  color: var(--color-second--active);
}

.button_type_filled:disabled {
  background-color: var(--color-first);
  border-color: var(--color-first);
  cursor: not-allowed;
  opacity: 0.4;
}

.button_type_filled svg {
  fill: var(--color-second);
}

.button_type_outline {
  border: 1px solid var(--color-first);
  background-color: unset;
  border-color: var(--color-first);
  color: var(--color-first-text, var(--color-first));
}

.button_type_outline:enabled:hover,
.button_type_outline:enabled:focus {
  border-color: var(--color-first--hover);
  color: var(--color-first-text--hover, var(--color-first--hover));
}

.button_type_outline:active:enabled {
  border-color: var(--color-first--active);
  color: var(--color-first-text--active, var(--color-first--active));
}

.button_type_outline:disabled {
  border-color: var(--color-first);
  cursor: not-allowed;
  opacity: 0.4;
}

.button_type_outline svg {
  fill: var(--color-first-text, var(--color-first));
}
.button_type_outline:enabled:hover svg,
.button_type_outline:enabled:focus svg {
  fill: var(--color-first-text--hover, var(--color-first--hover));
}

.button_type_outline:active:enabled svg {
  fill: var(--color-first-text--active, var(--color-first--active));
}

.button_type_invert {
  border: 1px solid var(--color-first);
  border-color: var(--color-first);
  color: var(--color-first-text, var(--color-first));
  background: radial-gradient(
      circle,
      var(--color-first--hover) 1%,
      var(--color-second--hover) 1%
    )
    center/0;
  transition: background 0.4s;
}

.button_type_invert:hover:enabled,
.button_type_invert:focus:enabled{
  background: radial-gradient(
      circle,
      var(--color-first--hover) 1%,
      var(--color-second--hover) 1%
    )
    center/15000%;
  border-color: var(--color-first--hover);
  color: var(--color-second--hover);
}

.button_type_invert:active:enabled {
  background: radial-gradient(
      circle,
      var(--color-first--active) 1%,
      var(--color-first--active) 1%
    )
    center/15000%;
  border-color: var(--color-first--active);
  color: var(--color-second--active);
}

.button_type_invert:disabled {
  border-color: var(--color-first);
  cursor: not-allowed;
  opacity: 0.4;
}

.button_type_invert svg {
  fill: var(--color-first-text, var(--color-first));
}

.button_type_invert:hover:enabled svg,
.button_type_invert:focus:enabled svg {
  fill: var(--color-second--hover);
}

.button_type_flat {
  border: 1px solid transparent;
  background-color: transparent;
  color: var(--color-first-text, var(--color-first));
}

.button_type_flat:hover:enabled,
.button_type_flat:focus:enabled {
  border-color: var(--color-second--hover);
  background: var(--color-second--hover);
  color: var(--color-first-text--hover, var(--color-first--hover));
}

.button_type_flat:active:enabled {
  border-color: var(--color-second--active);
  background: var(--color-second--active);
  color: var(--color-first-text--active, var(--color-first--active));
}

.button_type_flat:disabled {
  cursor: not-allowed;
  opacity: 0.4;
}

.button_type_flat svg {
  fill: var(--color-first-text, var(--color-first));
}

.button_type_flat:hover:enabled svg,
.button_type_flat:focus:enabled svg {
  fill: var(--color-first-text--hover, var(--color-first--hover));
}

.button_type_flat:enabled:active svg {
  fill: var(--color-first-text--active, var(--color-first--active));
}

/**/
.button_type_text {
  border: 1px solid transparent;
  background-color: transparent;
  color: var(--color-first-text, var(--color-first));
}

.button_type_text:hover:enabled,
.button_type_text:focus:enabled {
  color: var(--color-first-text--hover, var(--color-first--hover));
}

.button_type_text:active:enabled {
  color: var(--color-first-text--active, var(--color-first--active));
}

.button_type_text:disabled {
  cursor: not-allowed;
  opacity: 0.4;
}

.button_type_text svg {
  fill: var(--color-first-text, var(--color-first));
}

.button_type_text:hover:enabled svg,
.button_type_text:focus:enabled svg {
  fill: var(--color-first-text--hover, var(--color-first--hover));
}

.button_type_text:enabled:active svg {
  fill: var(--color-first-text--active, var(--color-first--active));
}

.button_type_halfColored {
  background-clip: padding-box;
  border: 1px solid var(--color-table-hover);
  background-color: var(--color-table-hover);
  color: var(--color-primary-main);
}

.button_type_halfColored:hover:enabled,
.button_type_halfColored:focus:enabled {
  background-color: rgba(92, 141, 246, 0.06);
  border-color: rgba(92, 141, 246, 0.2);
}

.button_type_halfColored:active:enabled {
  --background-color: rgba(92, 141, 246, 0.3);
  background-color: var(--background-color);
  border-color: var(--background-color);
}

.button_type_halfColored:disabled {
  --background-color: #f8fafe;
  background-color: var(--background-color);
  border-color: var(--background-color);
  color: var(--color-primary-disable);
  cursor: not-allowed;
}

.button_type_halfColored svg {
  fill: var(--color-primary-main);
}

.button_type_halfColored:disabled svg {
  fill: var(--color-primary-disable);
}

.button_type_grayed {
  background-clip: padding-box;
  --background-color: rgba(207, 217, 237, 0.2);
  border: 1px solid var(--background-color);
  background-color: var(--background-color);
  color: var(--color-text-secondary-main);
}

.button_type_grayed:hover:enabled,
.button_type_grayed:focus:enabled {
  --background-color: rgba(164, 179, 208, 0.5);
  background-color: var(--background-color);
  border-color: var(--background-color);
  color: var(--color-icon-press);
}

.button_type_grayed:active:enabled {
  --background-color: rgba(164, 179, 208, 0.5);
  background-color: var(--background-color);
  border-color: var(--background-color);
  color: var(--color-text-secondary-dark);
}

.button_type_grayed svg {
  fill: var(--color-text-secondary-main);
}

.button_type_grayed:hover:enabled svg,
.button_type_grayed:focus:enabled svg {
  fill: var(--color-icon-press);
}

.button_type_grayed:active:enabled svg {
  fill: var(--color-text-secondary-dark);
}
