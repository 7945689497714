.employeeTypesFilter__button {
  width: 30rem;
  height: 3.8rem;
  margin-right: 3rem;
  display: flex !important;
  justify-content: space-between !important;
}

.employeeTypesFilter__menu {
  width: 30rem;
}
