.root {
  background-color: var(--color-bg-main);
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  --header-height: 8rem;
  font-family: var(--font-family-primary);
  color: var(--color-text-primary);
}

.contentContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: var(--header-height);
  overflow: hidden;
}

.placeholder_container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}