.placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.placeholder__image {
  margin-top: 5rem;
}

.placeholder__title {
  margin-top: 2.2rem;
  margin-bottom: 2.2rem;
  text-align: center;
  width: 40rem;
}

.placeholder__message {
  margin-bottom: 2.2rem;
  text-align: center;
}

.placeholder__button {
  margin-top: 2.2rem;
  margin-bottom: 2.2rem;
  min-width: 20rem;
}
